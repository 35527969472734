"use client";
import React from "react";
import ReactDOM from "react-dom/client";
import Home from "./home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import "./common.css";
import "./media.css";
// import "./block-loader.css";
// import { GoogleOAuthProvider } from '@react-oauth/google';
// import ErrorScreen from "./components/common/errorScreen";
// import { ErrorBoundary } from "react-error-boundary";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<>
 <ToastContainer />
 <Home />
 </>  );
